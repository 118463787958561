import { StyledSelect } from 'CORE__UI/lines/dropdowns/OddsSelect/core__oddsSelectStyles';
import { BK_COLORS, FONT, GREYS } from 'UI/globals/colours';

const SelectStyles = {
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    padding: 16,
    color: FONT.black.standard,
    background: GREYS.white,
    fontWeight: state.isSelected ? 'bold!important' : 400,

    // !important is needed here because some outside styles are suppressing this rule
    '&:active': {
      background: 'white!important',
      fontWeight: 'bold',
    },
    // !important is needed here because some outside styles are suppressing this rule
    '&:hover': {
      fontWeight: 'bold',
      background: 'white!important',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: FONT.black.standard,
  }),
  menu: provided => ({
    ...provided,
    width: '12em',
    padding: 0,
    boxShadow: 'none',
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    border: 'none',
    boxShadow: `0 0 0 1px ${BK_COLORS.aliceBlue10}`,
    filter: `drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2))`,
    borderRadius: 4,
  }),
};

export { StyledSelect, SelectStyles };
